<template>
  <div class="container">
    <ModuleLocked v-if="!eCommerceModule" />
    <PageLoading v-else-if="getPaymentMethodsIsLoading"></PageLoading>
    <div class="row" v-else>
      <div class="col-12 my-3">
        <h4>Online {{ $t("transactions") }}</h4>
      </div>
      <div class="col-12 my-3">
        <div class="card">
          <div class="card-body" v-if="newOnlinePayType !== null">
            <div class="d-flex align-items-center justify-content-end">
              <img width="60" src="/images/payments/iyzico.png" alt="iyzico" />
              <img
                class="ml-2"
                width="60"
                src="/images/payments/paytr.png"
                alt="paytr"
              />
            </div>
            <!-- servis type iyzico ise -->
            <div v-if="iyzico.serviceType === 1">
              <h6 class="mt-4">Aktif online ödeme bağlantısı</h6>
              <h3 class="text-info">
                IYZICO
                <img
                  width="60"
                  src="/images/payments/iyzico.png"
                  alt="iyzico"
                />
              </h3>
              <div class="bg-light card mt-4">
                {{ $t("iyzicoApiDesc") }}
              </div>
              <div class="bg-light card mt-2">
                {{ $t("expIyzicoApi") }}
              </div>
            </div>
            <!-- servis type iyzico ise -->
            <div v-else>
              <h6 class="mt-4">Aktif online ödeme bağlantısı</h6>
              <h3 class="text-info">
                PAYTR
                <img
                  class="ml-2"
                  width="60"
                  src="/images/payments/paytr.png"
                  alt="paytr"
                />
              </h3>
              <div class="bg-light card mt-4">
                {{ $t("paytrApiDesc") }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <label for="key"> {{ $t("apiKey") }}</label>
                <input
                  name="key"
                  v-model="iyzico.key"
                  placeholder="Api Anahtarı"
                  class="form-control"
                  :type="viewKey.iyzico ? 'text' : 'password'"
                />
              </div>
              <div class="col-12 col-lg-6">
                <label for="security"> {{ $t("secretKey") }}</label>
                <input
                  v-model="iyzico.secret"
                  name="security"
                  class="form-control"
                  :type="viewKey.iyzico ? 'text' : 'password'"
                />
              </div>
              <!-- sadece paytr işlemlerinde alınır -->
              <div class="col-12 col-lg-6 mt-4" v-if="iyzico.serviceType === 6">
                <label for="merchant"> Merchant Id </label>
                <input
                  v-model="iyzico.merchantId"
                  name="merchant"
                  class="form-control"
                  :type="viewKey.iyzico ? 'text' : 'password'"
                />
              </div>
            </div>
            <div class="my-2 alert alert-warning">
              {{ $t("removePaymentDescription") }}
            </div>
            <div class="row mt-4 justify-content-between px-3">
              <button
                @click="newOnlinePayType = null"
                class="btn btn-pill bg-danger text-white mr-2"
              >
                {{ $t("removePayment") }}
              </button>
              <div>
                <button
                  @click="viewKey.iyzico = !viewKey.iyzico"
                  class="btn btn-pill donate bg-info mr-2"
                >
                  {{ viewKey.iyzico ? $t("hideKeys") : $t("showKeys") }}
                </button>
                <button
                  :disabled="isLoadingIyzico"
                  @click="
                    iyzico.id !== -1 ? SEND_UPDATE_IYZICO() : SEND_NEW_IYZICO()
                  "
                  class="btn btn-pill donate"
                >
                  <loading v-if="isLoadingIyzico"></loading>
                  <span v-else>
                    {{ iyzico.id !== -1 ? $t("update") : $t("save") }}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body my-5" v-else>
            <h6>{{ $t("whichPaymentDoyouwant") }}</h6>
            <div class="d-flex mt-3">
              <!-- 1 => iyzico -->
              <button class="btn" @click="setOnlinePayType(1)">
                <img
                  width="100"
                  src="/images/payments/iyzico.png"
                  alt="iyzico"
                />
              </button>
              <!-- 6 => paytr -->
              <button class="btn ml-3" @click="setOnlinePayType(6)">
                <img
                  class="ml-2"
                  width="100"
                  src="/images/payments/paytr.png"
                  alt="paytr"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 my-3">
        <h4>PAYPAL {{ $t("transactions") }}</h4>
      </div>
      <div class="col-12 my-3">
        <div class="card">
          <div class="card-body">
            <img width="60" src="/images/payments/paypal.png" alt="iyzico" />
            <div class="bg-light card mt-2">PayPal {{ $t("ApiDesc") }}</div>
            <!-- <div class="bg-light card mt-2">
              {{ $t("expIyzicoApi") }}
            </div> -->
            <div class="row">
              <div class="col-12 col-lg-6">
                <label for="key">{{ $t("apiKey") }}</label>
                <input
                  name="key"
                  v-model="paypal.key"
                  :placeholder="$t('secretKey')"
                  class="form-control"
                  :type="viewKey.paypal ? 'text' : 'password'"
                />
              </div>
              <div class="col-12 col-lg-6">
                <label for="security"> {{ $t("secretKey") }} </label>
                <input
                  v-model="paypal.secret"
                  name="security"
                  class="form-control"
                  :type="viewKey.paypal ? 'text' : 'password'"
                />
              </div>
            </div>
            <div class="row mt-4 justify-content-end">
              <button
                @click="viewKey.paypal = !viewKey.paypal"
                class="btn btn-pill bg-info donate mr-2"
              >
                {{ viewKey.paypal ? $t("hideKeys") : $t("showKeys") }}
              </button>
              <button
                @click="
                  paypal.id !== -1 ? SEND_UPDATE_PAYPAL() : SEND_NEW_PAYPAL()
                "
                class="btn btn-pill donate"
              >
                {{ paypal.id !== -1 ? $t("update") : $t("save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 my-3">
        <h4>
          EFT - HAVALE - Payoneer
          <button @click="SET_NEW_IBAN()" class="btn btn-xs btn-pill btn-dark">
            + {{ $t("addIBAN") }}
          </button>
        </h4>
      </div>
      <div class="col-12 my-1" v-for="(other, i) in others" :key="i">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-12 col-lg-3">
                <div><small>Banka Adı</small></div>
                <img
                  v-if="other.bankName.includes('Payoneer')"
                  src="/images/payments/Payoneer.webp"
                  width="40"
                  alt="Payoneer"
                />
                {{ other.bankName }}
              </div>
              <div class="col-12 col-lg-3">
                <div><small>IBAN Sahibi</small></div>
                {{ other.receiverName }}
              </div>
              <div
                v-if="!other.bankName.includes('Payoneer')"
                class="col-12 col-lg-4"
              >
                <div><small>IBAN</small></div>
                {{ other.iban }}
              </div>
              <div class="col-12 col-lg-2">
                <button @click="SET_UPDATE_IBAN(other)" class="btn btn-pill">
                  Düzenle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 my-3">
        <h4>
          Payoneer İŞLEMLERİ
          <button
            @click="SET_NEW_IBAN('activePayoneer', 'showPayoneer')"
            class="btn btn-xs btn-pill btn-dark"
          >
            + IBAN Ekle
          </button>
        </h4>
      </div>
      <div class="col-12 my-3" v-for="(Payoneer, i) in Payoneers" :key="i">
        <div class="card">
          <div class="card-body">
            {{ Payoneer }}
          </div>
        </div>
      </div> -->
    </div>
    <Modal @ok="SEND_OTHER" v-model="showOther" persistent>
      <div class="row">
        <div class="col-12">
          <div>İşlem Seçin</div>
          <input
            type="radio"
            name="Payoneer"
            v-model="activeOther.isPayoneer"
            :value="1"
            id="Payoneer"
          />
          <label class="mx-2" for="Payoneer"> Payoneer</label>
          <input
            type="radio"
            name="iban"
            v-model="activeOther.isPayoneer"
            :value="0"
            id="iban"
          />
          <label class="mx-2" for="iban"> Türkiye Bankaları</label>
          <hr />
        </div>
        <div class="col-12 col-lg-6 mt-3">
          <label for="key">Banka Adı</label>
          <input
            name="key"
            v-model="activeOther.bankName"
            placeholder="Banka Adı"
            class="form-control"
            type="text"
          />
        </div>
        <div class="col-12 col-lg-6 mt-3">
          <label for="security">Hesap sahibi</label>
          <input
            v-model="activeOther.receiverName"
            name="security"
            placeholder="IBAN sahibi"
            class="form-control"
            type="text"
          />
        </div>
        <div v-if="activeOther.isPayoneer === 0" class="col-12 col-lg-12 mt-3">
          <label for="security">IBAN</label>
          <input
            v-model="activeOther.iban"
            name="security"
            placeholder="IBAN"
            class="form-control"
            type="text"
          />
        </div>
        <div v-else class="col-12 mt-3">
          <label for="">Payoneer Açıklaması</label>
          <vue-editor
            id="editor"
            :editorToolbar="[
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
              [{ size: ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline', 'strike'],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
              ],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
              [{ color: [] }, { background: [] }],
              ['link'],
              ['clean'],
            ]"
            v-model="activeOther.wiseInfo"
          >
          </vue-editor>
        </div>
      </div>
    </Modal>
    <Modal v-model="showPayoneer">
      <div class="row">
        <div class="col-12 col-lg-6 mt-3">
          <label for="key">Banka Adı</label>
          <input
            name="key"
            v-model="activePayoneer.bankName"
            placeholder="Banka Adı"
            class="form-control"
            type="text"
          />
        </div>
        <div class="col-12 col-lg-6 mt-3">
          <label for="security">IBAN sahibi</label>
          <input
            v-model="activePayoneer.receiverName"
            name="security"
            placeholder="IBAN sahibi"
            class="form-control"
            type="text"
          />
        </div>
        <div class="col-12 col-lg-12 mt-3">
          <label for="security">IBAN</label>
          <input
            v-model="activePayoneer.iban"
            name="security"
            placeholder="IBAN"
            class="form-control"
            type="text"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import PageLoading from "../Shared/PageLoading.vue";
import Modal from "../Shared/Modal/index.vue";
import Loading from "../Shared/Loading.vue";
import { VueEditor } from "vue2-editor";
import Modules from "../../mixins/Modules.js";
import ModuleLocked from "../Packages/ModuleLocked.vue";
export default {
  mixins: [Modules],
  components: { PageLoading, Modal, Loading, VueEditor, ModuleLocked },
  data() {
    return {
      viewKey: {
        iyzico: false,
        paypal: false,
      },
      paypal: {
        id: -1,
        key: "",
        secret: "",
      },
      iyzico: {
        id: -1,
        key: "",
        secret: "",
      },
      others: [],
      showOther: false,
      Payoneers: [],
      showPayoneer: false,
      isLoadingIyzico: false,
      isLoadingPaypal: false,
      activeOther: {
        id: -1,
        bankName: "",
        iban: "",
        receiverName: "",
        wiseInfo: "",
        isPayoneer: 0,
      },
      activePayoneer: {
        id: -1,
        bankName: "",
        iban: "",
        receiverName: "",
      },
      newOnlinePayType: null,
    };
  },
  watch: {
    getPaymentMethodsIsLoading() {
      this.SET_INITIAL_PAGE();
    },
  },
  computed: {
    getPaymentMethods() {
      return this.$store.state.PaymentMethods.paymentMethods;
    },
    getPaymentMethodsIsLoading() {
      return this.$store.state.PaymentMethods.paymentMethodsIsLoading;
    },
  },
  methods: {
    setOnlinePayType(type) {
      this.newOnlinePayType = type;
      // seçilen ile aktif olan typelar aynı değilse formun içini boşalt
      if (
        (type === 1 && this.iyzico.serviceType !== 1) ||
        (type === 6 && this.iyzico.serviceType !== 6)
      ) {
        this.iyzico = {
          ...this.iyzico,
          merchantId: "",
          secret: "",
          key: "",
          serviceType: type,
          baseUrl:
            type === 1
              ? "https://api.iyzipay.com/"
              : "https://www.paytr.com/odeme/api/get-token",
        };
      }
    },
    async SEND_NEW_IYZICO() {
      this.isLoadingIyzico = true;
      const response = await this.$store.dispatch(
        "SEND_NEW_PAYMENT_METHODS_IYZICO",
        {
          apiKey: this.iyzico.key,
          secretKey: this.iyzico.secret,
          serviceType: this.iyzico.serviceType,
          merchantId: this.iyzico.merchantId,
          baseUrl: this.iyzico.baseUrl,
        }
      );
      if (response) {
        this.showOther = false;
        this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", true);
        setTimeout(() => {
          this.SET_INITIAL_PAGE();
          this.isLoadingIyzico = false;
          this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", false);
        }, 1000);
      }
    },
    async SEND_UPDATE_IYZICO() {
      this.isLoadingIyzico = true;
      const response = await this.$store.dispatch(
        "SEND_UPDATE_PAYMENT_METHODS_IYZICO",
        {
          apiKey: this.iyzico.key,
          secretKey: this.iyzico.secret,
          serviceType: this.iyzico.serviceType,
          merchantId: this.iyzico.merchantId,
          baseUrl: this.iyzico.baseUrl,
          id: this.iyzico.id,
        }
      );
      if (response) {
        this.showOther = false;
        this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", true);
        setTimeout(() => {
          this.SET_INITIAL_PAGE();
          this.isLoadingIyzico = false;
          this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", false);
        }, 1000);
      }
    },
    async SEND_UPDATE_PAYPAL() {
      this.isLoadingIyzico = true;
      const response = await this.$store.dispatch(
        "SEND_UPDATE_PAYMENT_METHODS_PAYPAL",
        {
          clientId: this.paypal.key,
          clientSecret: this.paypal.secret,
          id: this.paypal.id,
        }
      );
      if (response) {
        this.showOther = false;
        this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", true);
        setTimeout(() => {
          this.SET_INITIAL_PAGE();
          this.isLoadingIyzico = false;
          this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", false);
        }, 1000);
      }
    },
    async SEND_NEW_PAYPAL() {
      const response = await this.$store.dispatch(
        "SEND_NEW_PAYMENT_METHODS_PAYPAL",
        {
          clientId: this.paypal.key,
          clientSecret: this.paypal.secret,
        }
      );
      if (response) {
        this.showOther = false;
        this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", true);
        setTimeout(() => {
          this.SET_INITIAL_PAGE();
          this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", false);
        }, 1000);
      }
    },
    async SEND_OTHER(loading) {
      loading(true);
      if (this.activeOther.id === -1) {
        const response = await this.$store.dispatch(
          "SEND_NEW_PAYMENT_METHODS_TRANSFER",
          {
            bankName:
              (this.activeOther.isPayoneer === 1 ? "Payoneer " : "") +
              this.activeOther.bankName,
            iban: this.activeOther.iban,
            receiverName: this.activeOther.receiverName,
            wiseInfo: this.activeOther.wiseInfo,
          }
        );
        if (response) {
          this.showOther = false;
          this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", true);
          setTimeout(() => {
            this.SET_INITIAL_PAGE();
            this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", false);
          }, 1000);
        }
      } else {
        const response = await this.$store.dispatch(
          "SEND_UPDATE_PAYMENT_METHODS_TRANSFER",
          {
            bankName:
              (this.activeOther.isPayoneer === 1 ? "Payoneer " : "") +
              this.activeOther.bankName,
            iban: this.activeOther.iban,
            receiverName: this.activeOther.receiverName,
            wiseInfo: this.activeOther.wiseInfo,
            id: this.activeOther.id,
          }
        );
        if (response) {
          this.showOther = false;
          this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", true);
          setTimeout(() => {
            this.SET_INITIAL_PAGE();
            this.$store.commit("SET_PAYMENT_METHODS_IS_LOADING", false);
          }, 1000);
        }
      }
      loading(false);
    },
    SET_NEW_IBAN(key = "activeOther", modal = "showOther") {
      this[key] = {
        id: -1,
        bankName: "",
        iban: "",
        receiverName: "",
        isPayoneer: 0,
        wiseInfo: "",
      };
      this[modal] = true;
    },
    SET_UPDATE_IBAN(other, key = "activeOther", modal = "showOther") {
      this[key] = {
        ...other,
        bankName: other.bankName.includes("Payoneer ")
          ? other.bankName.split("Payoneer ")[1]
          : other.bankName,
        isPayoneer: other.bankName.includes("Payoneer ") ? 1 : 0,
      };
      this[modal] = true;
    },
    SET_INITIAL_PAGE() {
      const iyzico = this.getPaymentMethods.find((p) => p.type === 1);
      const paypal = this.getPaymentMethods.find((p) => p.type === 2);
      const others = this.getPaymentMethods.filter((p) => p.type === 3);
      const Payoneers = this.getPaymentMethods.filter((p) => p.type === 4);
      if (iyzico) {
        this.iyzico = iyzico;
        this.newOnlinePayType = iyzico.serviceType;
      }
      if (paypal) this.paypal = paypal;
      if (others) this.others = others;
      if (Payoneers) this.Payoneers = Payoneers;
    },
  },
  mounted() {
    this.SET_INITIAL_PAGE();
  },
};
</script>

<style></style>
